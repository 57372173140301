import classes from "./../../../content.module.css";

const Beautiful = () => {

    return (
        <>
            <h2 className={classes.Title}>Самый красивый иероглиф</h2>
            <p>Каждый месяц мы проводим захватывающий конкурс, в котором учащиеся соревнуются за звание «Мастер Китайского»</p>
            <img src="/beautiful/1.jpg" loading="lazy" alt="" className={classes.Image} />
            <p>В ходе конкурса участники могут проявить себя в каждом из трех разделов: Мастер Перевода, Мастер Легенд и Мастер Каллиграфии</p>
            <div className={classes.Group}>
                <img src="/beautiful/2.jpg" loading="lazy" alt="" className={classes.Image} />
                <img src="/beautiful/3.jpg" loading="lazy" alt="" className={classes.Image} />
            </div>
            <p>Победитель, получивший звание Мастер Китайского, получает уникальную возможность – в течение всего следующего месяца за выполнение заданий он сможет получать 1 дополнительный изумруд</p>
            <div className={classes.Group}>
                <img src="/beautiful/4.jpg" loading="lazy" alt="" className={classes.Image} />
                <img src="/beautiful/5.jpg" loading="lazy" alt="" className={classes.Image} />
            </div>
            <p>Учащиеся с нетерпением ждут этого события, ведь им предоставляется возможность не только проверить количество и качество усвоенного материала, но и солидно пополнить свои "кошельки"</p>
            <div className={classes.Group}>
                <img src="/beautiful/6.jpg" loading="lazy" alt="" className={classes.Image} />
                <img src="/beautiful/7.jpg" loading="lazy" alt="" className={classes.Image} />
            </div>
        </>
    );
};

export default Beautiful;
