import { Routes, Route } from "react-router-dom";
import Main from "./components/Main/Main";
import About from "./components/Content/About";
import Programm from "./components/Content/Programm";
import Tea from "./components/Content/Tea";
import Classes from "./components/Content/Classes";
import Contacts from "./components/Content/Contacts/Contacts"
import Beautiful from "./components/Content/Events/Beautiful"

const AppRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/about' element={<About />} />
            <Route path='/programm' element={<Programm />} />
            <Route path='/tea' element={<Tea />} />
            <Route path='/classes' element={<Classes />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/events/beautiful' element={<Beautiful />} />
        </Routes>
    );
};

export default AppRoutes;
