import classes from "./TabPanel.module.css";
import "primeicons/primeicons.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const TabPanel = ({ isHidden, setIsHidden }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isItemExpand, setIsItemExpand] = useState(location.pathname.split('/')[1] === 'events')

    const navBarItem = (key, label, path, icon) =>
        <li key={key} className={`${classes.navBarItemWrapper} ${location.pathname === path ? classes.activeTab : ''}`}
            onClick={() => { navigate(path); setIsItemExpand(false) }}>
            <div className={classes.navBarItem}>
                <div className={classes.navBarInnerItem}>
                    <i className={`${classes.icon} ${icon}`} />
                    {label}
                </div>
            </div>
        </li>

    const navBarItemList = (key, label, path, icon) =>
        <li key={key} className={`${location.pathname.split('/')[1] === path.slice(1, -1) ? classes.activeTab : ''}`}
            onClick={() => {navigate(`${path}beautiful`); setIsItemExpand(true) }}>
            <div className={classes.navBarItemWrapper}>
                <div className={classes.navBarItem}>
                    <div className={classes.navBarInnerItem}>
                        <i className={`${classes.icon} ${icon}`} />
                        {label}
                    </div>
                    <i className={`${classes.icon} pi-chevron-down`} />
                </div>
            </div>
            <ul className={classes.subOptions}>{isItemExpand && navBarItem(0, 'Самый красивый иероглиф', '/events/beautiful', 'pi-palette')}</ul>
        </li>

    return (
        <div className={`${classes.container} ${isHidden ? classes.isHidden : ""}`}>
            <div className={classes.label}>МАСТЕР КИТАЙСКОГО</div>
            <ul className={classes.options}>
                {navBarItem(0, 'Главная', '/', 'pi-home')}
                {navBarItem(1, 'О себе', '/about/', 'pi pi-users')}
                {navBarItem(2, 'Программа', '/programm/', 'pi-graduation-cap')}
                {navBarItemList(3, 'Мероприятия', '/events/', 'pi-calendar')}
                {navBarItem(4, 'Чайная церемония', '/tea/', 'pi-heart')}
                {navBarItem(5, 'Мастер-классы', '/classes/', 'pi-pencil')}
                {navBarItem(6, 'Контакты', '/contacts/', 'pi-phone')}
            </ul>
            <div className={classes.hideButtonContainer}>
                <button className={classes.hideButton} onClick={() => setIsHidden(!isHidden)}>
                    <i className={isHidden ? 'pi pi-caret-right' : 'pi pi-caret-left'} />
                </button>
            </div>
        </div>
    );
};

export default TabPanel;
