import classes from "./../../content.module.css";

const About = () => {

    return (
        <>
            <h2 className={classes.Title}>О нас</h2>
            <p>Добро пожаловать в «МАСТЕР КИТАЙСКОГО»! Мы предлагаем уникальный подход в обучении китайскому языку: никаких скучных уроков и советского стиля</p>
            <img src="/about/1.jpg" loading="lazy" alt="" className={classes.Image} />
            <p>На наших занятиях только весёлая и дружная атмосфера с погружением в тематические ситуации через игры и песни на китайском языке 🎉</p>
            <img src="/about/2.jpg" loading="lazy" alt="" className={classes.Image} />
            <h2>Наша Философия 🧐</h2>
            <p>Мы минимизируем домашние задания, так как основной материал осваивается прямо на занятиях. Вам остается только повторить пройденное! 🧑🏻‍💻</p>
            <img src="/about/3.jpg" loading="lazy" alt="" className={classes.Image} />
            <h2>Индивидуальный подход 👩🏻‍🏫</h2>
            <p>Мы формируем небольшие группы из 6-8 человек, чтобы каждый ученик мог ощутить заботу и внимание, получая индивидуальный подход на каждом занятии 🙋🏻‍♂️</p>
           
            <div className={classes.Group}>
                <img src="/about/5.jpg" loading="lazy" alt="" className={classes.Image} />
                <img src="/about/8.jpg" loading="lazy" alt="" className={`${classes.Image} ${classes.HideMobile}`}/>
            </div>     
            <p>Присоединяйтесь к нам и сделайте обучение китайскому языку ярким и незабываемым 🤩</p>
            <img src="/about/6.jpg" loading="lazy" alt="" className={classes.Image} />  
        </>
    );
};

export default About;
