import classes from "./../../content.module.css";

const Programm = () => {

    return (
        <>
            <h2 className={classes.Title}>Описание программы</h2>
            <p>Наше обучение построено на интерактивном формате, который делает процесс изучения языка увлекательным и эффективным</p>
            <img src="/programm/1.jpg" loading="lazy" alt="" className={classes.Image} />
            <h2>⦁ Авторские SmallTalks</h2>
            <video className={classes.Video} controls>
                <source src="/programm/1.mp4" loading="lazy" type="video/mp4" />
            </video>
            <video className={classes.Video} controls>
                <source src="/programm/2.mp4" loading="lazy" type="video/mp4" />
            </video>
            <p>Эти обучающие песни помогут вам легко запомнить основные фразы и выражения. Через музыку, ритм и жесты мы создаем атмосферу, в которой язык становится ближе и понятнее</p>
            <h2>⦁ Ситуационные игры на китайском</h2>
            <p>Мы активно используем игровые интерактивные методики, которые погружают вас в реальные коммуникативные ситуации</p>     
            <img src="/programm/2.jpg" loading="lazy" alt="" className={classes.Image} />
            <p>Это позволяет осваивать язык в контексте, развивать навыки общения и уверенность в использовании китайского в повседневной жизни</p>    
            <img src="/programm/3.jpg" loading="lazy" alt="" className={classes.Image} />
            <h2>⦁ Отсутствие оценок </h2>
            <div className={classes.Group}>
                <img src="/programm/4.jpg" loading="lazy" alt="" className={classes.Image} />
                <img src="/programm/5.jpg" loading="lazy" alt="" className={classes.Image} />
            </div>
            <p>В нашей школе мы ставим во главу угла интерес и удовольствие от обучения. Вместо традиционной системы оценок, наши ученики за свои достижения копят нефриты, за которые в конце сезона могут приобрести различные призы на аукционе. Таким образом, появляется дух соперничества и мотивация в процессе обучения</p>
            <img src="/common/panda.png" loading="lazy" alt="" className={classes.Panda} />
            <table>
                <tbody>
                    <tr>
                        <th>Условия обучения</th>
                        <th>Описание</th>
                    </tr>
                    <tr>
                        <td>Формат занятий</td>
                        <td>Групповые занятия</td>
                    </tr>
                    <tr>
                        <td>Частота</td>
                        <td>2 занятия в неделю</td>
                    </tr>
                    <tr>
                        <td>Продолжительность</td>
                        <td>60 мин</td>
                    </tr>
                </tbody>
            </table>
            <p className={classes.left}><span className={classes.bold}>- Цена одного занятия: </span>750 рублей</p>
            <p className={classes.left}><span className={classes.bold}>- Общая стоимость за месяц: </span>6000 рублей (8 занятий)</p>
            <p className={classes.left}><span className={classes.bolder}>*</span>Оплата осуществляется сразу за месяц. Мы предлагаем гибкие условия для обучения, чтобы каждый ученик мог выбрать наиболее удобный для себя график</p>
            <p className={classes.left}><span className={classes.bolder}>**</span>До конца 2024 года на ПЕРВЫЙ месяц обучения действует скидка 7%</p>
            <p className={classes.left}>Начните ваше увлекательное путешествие в китайский язык по выгодной цене!</p>
        </>
    );
};

export default Programm;
