import classes from "./Background.module.css";

const Background = ({isInitialTab}) => {

    return (
        <div className={classes.BackgroundContainer}>
            <div className={classes.BackgroundGradient} />
            <div className={`${classes.BackgroundFon} ${isInitialTab ? "" : classes.CutBackground}`} />
            {!isInitialTab && <div className={classes.BackgroundFonRevert} />}
        </div>
    );
};

export default Background;