import { useState, useEffect } from "react";
import './App.css';
import TabPanel from "./components/TabPanel/TabPanel";
import Background from './components/Background/Background';
import ContentContainer from './components/ContentContainer/ContentContainer';
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const [isInitialTab, setIsInitialTab] = useState(location.pathname === '/' || location.pathname === '');
  const [isPanelHidden, setIsPanelHidden] = useState(false);

  useEffect(() => {setIsInitialTab(location.pathname === '/' || location.pathname === ''); window.scrollTo(0, 0)}, [location]);

  return (
    <>
      <Background isInitialTab={isInitialTab} />
      <div style={{ display: "flex", minWidth: "100%" }}>
        <TabPanel isHidden={isPanelHidden} setIsHidden={setIsPanelHidden} />
        <ContentContainer isInitialTab={isInitialTab} isPanelHidden={isPanelHidden} />
      </div>
    </>
  );
};

export default App;