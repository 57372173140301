import classes from "./Main.module.css";

const Main = () => {

    return (
        <>
            <div className={classes.BackgroundImage}/>
            <div className={classes.LabelContainer}>
                <div className={classes.Label}>中文师父</div>
                <div className={classes.LabelDescription}>«МАСТЕР КИТАЙСКОГО»</div>
            </div>
        </>
    );
};

export default Main;
