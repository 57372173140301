import classes from "./ContentContainer.module.css";
import AppRoutes from "./../../AppRoutes";

const ContentContainer = ({ isInitialTab, isPanelHidden }) => {

    return isInitialTab ?
        <AppRoutes /> :
        <div className={classes.Content}>
            <div className={`${classes.Container} ${isPanelHidden ? "" : classes.HiddenPanel}`}>
                <div className={classes.TitleContainer}/>
                <AppRoutes />
            </div>
        </div>
};

export default ContentContainer;