import classes from "./../../content.module.css";

const Tea = () => {

    return (
        <>
            <h2 className={classes.Title}>Чайная церемония</h2>
            <div className={classes.Group}>
                <img src="/tea/1.jpg" alt="" className={classes.Image} />
                <img src="/tea/2.jpg" alt="" className={classes.Image} />
            </div>
            <p>Если у вас два занятия в один день, у нас для вас замечательная новость! В перерывах между уроками мы устраиваем настоящую китайскую чайную церемонию!</p>
            <div className={classes.Group}>
                <img src="/tea/3.jpg" alt="" className={classes.Image} />
                <img src="/tea/4.jpg" alt="" className={classes.Image} />
            </div>
            <p>Во время этой уникальной паузы мы создаем умиротворенную атмосферу, где каждый может насладиться знаменитыми сортами чая — улуном или пуэром, которые подаются в изысканном китайском фарфоре</p>
            <img src="/tea/5.jpg" alt="" className={classes.Image} />
            <p>Китайская чайная церемония — это не просто ритуал, а возможность остановиться, расслабиться и погрузиться в богатую культуру, обычаи и традиции Китая</p>
            <img src="/tea/6.jpg" alt="" className={classes.Image} />
        </>
    );
};

export default Tea;
