import classes from "./Contacts.module.css";
import { useState } from "react";

const Contacts = () => {
    const [copiedPhone, setCopiedPhone] = useState(false);

    const copyPhone = async (text) => { await navigator.clipboard.writeText(text); setCopiedPhone(text) };

    return (
        <>
            <h2 className={classes.Title}>Контакты</h2>

            <div className={classes.PhoneContainer}>
                <div className={classes.PhoneLabel}>Телефон:</div>
                <ul className={classes.PhoneList}>
                    <li>
                        <a href="tel:+79250911940">+79250911940</a>
                        <i className={`${classes.icon} pi-clone`} onClick={e => copyPhone(e.target.parentElement.innerText)} />
                        {copiedPhone === '+79250911940' && <i className={`${classes.icon} pi-check`} />}
                    </li>
                    <li>
                        <a href="tel:+79038839291">+79038839291</a>
                        <i className={`${classes.icon} pi-clone`} onClick={e => copyPhone(e.target.parentElement.innerText)} />
                        {copiedPhone === '+79038839291' && <i className={`${classes.icon} pi-check`} />}
                    </li>
                </ul>
            </div>
            <p className={classes.Icons}>
                <a href="https://t.me/mashutina_m"><i className={classes.TgIcon} /></a>
                <a href="https://wa.me/79250911940"><i className={classes.WatsappIcon} /></a>
                <a href="https://vk.com/club227649638"><i className={classes.VkIcon} /></a>
            </p>
            <p className={classes.Address}><span className={classes.bolder}>Местоположение: </span>г. Москва, Дмитровское шоссе, 51к1</p>
            <p>(рядом со станцией МЦД Петровско-Разумовская)</p>
            <div className={classes.MapContainer}>
                <a href="https://yandex.ru/maps/org/master_kitayskogo/44691812748/?utm_medium=mapframe&utm_source=maps" className={classes.MapLink}>Мастер Китайского</a>
                <a href="https://yandex.ru/maps/213/moscow/category/foreign_language_courses/184106160/?utm_medium=mapframe&utm_source=maps" className={classes.Map}>Курсы иностранных языков в Москве</a>
                <iframe src="https://yandex.ru/map-widget/v1/?ll=37.572780%2C55.844383&mode=poi&poi%5Bpoint%5D=37.568322%2C55.842412&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D44691812748&z=14.96" title="Мастер Китайского" className={classes.MapWidget} />
            </div>
        </>
    )
}

export default Contacts;