import classes from "./../../content.module.css";

const Classes = () => {
    return (
        <>
            <h2 className={classes.Title}>Мастер-классы</h2>
            <h2>Погружение в китайскую культуру через творчество</h2>
            <img src="/classes/1.jpg" alt="" loading="lazy" className={classes.Image} />
            <p>Наши мастер-классы – это уникальная возможность познакомиться с богатой историей и культурой Китая, а также попробовать свои силы в создании оригинальных изделий своими руками</p>
            <h2>От теории к практике</h2>
            <div className={classes.Group}>
                <img src="/classes/2.jpg" alt="" loading="lazy" className={classes.Image} />
                <img src="/classes/3.jpg" alt="" loading="lazy" className={classes.Image} />
            </div>
            <p>Каждый мастер-класс включает теоретическую часть, во время которой вы сможете узнать много нового о жителях Поднебесной, их философии жизни, традициях и обычаях</p>
            <div className={classes.Group}>
                <img src="/classes/4.jpg" alt="" loading="lazy" className={classes.Image} />
                <img src="/classes/5.jpg" alt="" loading="lazy" className={classes.Image} />
            </div>
            <p>Практическая часть включает в себя создание изделия. В зависимости от темы мастер-класса, вы сможете сделать что-то своими руками, будь то традиционная китайская роспись, создание оригинальных фигурок или других поделок, которые могут стать замечательным украшением для вашего дома</p>
            <div className={classes.Group}>
                <img src="/classes/6.jpg" alt="" loading="lazy" className={classes.Image} />
                <img src="/classes/7.jpg" alt="" loading="lazy" className={classes.Image} />
            </div>
            <h2>Чайная церемония</h2>
            <div className={classes.Group}>
                <img src="/classes/8.jpg" alt="" loading="lazy"className={classes.Image} />
                <img src="/classes/9.jpg" alt="" loading="lazy"className={classes.Image} />
            </div>
            <p>В конце каждого мастер-класса мы устраиваем традиционную китайскую чайную церемонию. Это не только способ расслабиться и насладиться атмосферой, но и возможность обсудить полученные знания и поделиться впечатлениями с другими участниками</p>
            <img src="/common/panda.png" alt="" loading="lazy" className={classes.Panda} />
            <h2>Информация о мастер-классах:</h2>
            <table>
                <tbody>
                    <tr>
                        <th>Условия обучения</th>
                        <th>Описание</th>
                    </tr>
                    <tr>
                        <td>Цена</td>
                        <td>от 750 руб</td>
                    </tr>
                    <tr>
                        <td>Продолжительность</td>
                        <td>от 1,5 часов</td>
                    </tr>
                    <tr>
                        <td>Количество участников</td>
                        <td>8 человек</td>
                    </tr>
                </tbody>
            </table>
            <p>Мы ограничиваем количество участников, чтобы обеспечить активное вовлечение в процесс каждого и предоставить участникам возможность задавать вопросы 😉</p>
            <img src="/classes/10.jpg" alt="" loading="lazy" className={classes.Image} />
        </>
    )
}

export default Classes;